<template>
<div>
       <v-sheet color="grey lighten-3" min-height="10vh">
    <v-container fill-height>
      <v-row dense justify="start" >
    <div class="transition-swing text-h4 mt-10 mb-2" >
    Salesrep
    </div>
      </v-row>
    </v-container>
  </v-sheet>
<v-container>
  <v-row dense justify="center">
  <v-card width="100%">
    <v-container>
    <v-row>
    <v-card-text>
        <v-data-table 
          dense
          :headers="headers"
          :items="items"
          item-key="id"
          :items-per-page="pageSize"
          :server-items-length="itemsCount"
          @update:options="paginate"
          :search="search"
          :loading="loading"
          class="elevation-1"
        >

<template v-slot:no-data>
  <v-container>
    <v-row justify="center" align="center">
      <v-col xl="3" lg="3" md="6"  cols="12">
     <v-alert v-if="!salesrep"
      dense
      type="info"
    >
      Please select a salesrep
    </v-alert>
     <v-alert v-else
      dense
      type="info">
        No data
     </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>
          <template v-slot:top>
          <v-sheet color="grey lighten-5" elevation="2" class="mb-6">
      
<v-container>
  <v-row>
 
           <v-col lg="3" md="6" sm="6" cols="12">
             <salesrepselect :multiple="false"/>
           </v-col>

        <v-col lg="2" md="6" sm="6" cols="12">
            <v-radio-group :value="lifecycleType" row>
              <v-radio
                v-for="n in lifeCycles"
                :key="n.id"
                :label="n.name"
                :value="n.id"
                @change="setType(n.id)"
              ></v-radio>
            </v-radio-group>
        </v-col>
        <v-col lg="2" md="6" sm="6" cols="12">
            <v-checkbox
            v-model="showOnlyEngaged"
            label="Only engaged"
            @change="refresh(1, 10, '')"
            >
            </v-checkbox>
        </v-col>
         <v-spacer></v-spacer>
                <v-col cols="4">
                  <v-text-field
                    dense
                    v-model="search"
                    label="Search on org.no, name or email"
                    class="mr-4"
                    @change="performSearch"
                  ></v-text-field>
                </v-col>
              </v-row>
  
            </v-container>
          </v-sheet>
          </template>
<template v-slot:item.actions="{ item }">
 <v-container>
   <v-row justify="center" align="center">
     <v-col cols="6">
      <v-switch
      dense
      v-model="item.isEngaged"
      @change="toggleEngagement(item)"
    ></v-switch>
     </v-col>
     <v-col cols="6">
    <!-- <v-icon @click="showEngagementHistory(item)">
        mdi-history
    </v-icon> -->
     </v-col>
   </v-row>
 </v-container>
    </template>          
        </v-data-table>
    </v-card-text>
    <v-card-text>
                   <v-btn @click="exportdata" color="info" small :loading="loadingDownload" :disabled="!salesrep" >
                     Download
                <v-icon dark>mdi-download</v-icon>
              </v-btn>
    </v-card-text>
    </v-row>
    </v-container>
  </v-card>

  
  </v-row>
</v-container>

<v-dialog
      v-model="dialog"
      width="500"
    >
<v-card>
      <organizationengagements  v-if="selected" :organization="selected" :lifecycleType="lifecycleType" :key="selected.organizationId" />

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            I accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

</div>
</template>

<style scoped>

#toggleFilter {
  position: absolute;
  bottom: 0;
  margin-bottom: 3em;
  z-index: 1;
}



</style>
  
<script>

import salesrepselect from "@/components/filters/autocompletes/SalesrepSelect";
import organizationengagements from "@/components/salesrep/OrganizationEngagements";
import salesrepService from "@/services/SalesrepService";


export default {

  components: {
    salesrepselect,
    organizationengagements
    // rewardlevelselect,
  },
  data: () => ({
    showOnlyEngaged: false,
    selected: null,
    dialog: false,
    lifeCycles: [{id: 1, name: "Customer"}, {id: 2, name: "Prospect"} ],
    pageNumber: 1,
      pageSize: 10,
      search: "",
      items: [],
      itemsCount: 0,
      loading: false,
      loadingDownload: false,
      salesrepDetails: null
     
  }),
  mounted() {
    
  },
  watch: {
    async salesrep(){
      await this.GetSalesrepDetails();
      this.search = ""
      this.refresh(1, 10, "")
    }
  },
  computed: {
      lifecycleType(){
        return this.$store.getters["filter/filterRequest"].lifecycleType
      },   
      lifecycleTypeSelected(){
        return this.lifeCycles.find(i => i.id == this.lifecycleType)
      },         
      salesrep(){
        return this.$store.getters["filter/filterRequest"].salesreps[0]
      },         
    showSalesColumns(){
      return this.lifecycleType == 1
    },
    headers() {
       return [
      { width:"50", text: "Engaged", value: "actions", sortable: false },
      {
        text: "Org.no",
        sortable: false,
        value: "organizationNumber",
        align: 'start'
      },     
      {
        text: "Segment",
        sortable: false,
        value: "segment",
        align: 'start'
      },        
      {
        text: "Name",
        sortable: false,
        value: "name",
        align: 'start'
      },
      {
        text: "Address",
        sortable: false,
        value: "addresslineFull",
        align: 'start'
      },  
      {
        text: "Email",
        sortable: false,
        value: "email",
        align: 'start'
      },
      {
        text: "Tele",
        sortable: false,
        value: "phone",
        align: 'start'
      },   
      {
        text: "SNI",
        sortable: false,
        value: "lineOfBusiness",
        align: 'start'
      },
      {
        text: "#TL",
        sortable: false,
        value: "vehiclesTotalHeavy",
        align: 'start'
      },                                 
        {
        text: "#LL",
        sortable: false,
        value: "vehiclesTotalLight",
        align: 'start'
      },          
      {
        text: "m3 R12",
        sortable: false,
        value: "quantityR12_Formatted",
        align: this.showSalesColumns ? 'start' :' d-none'
      }, 
      {
        text: "m3 YTD",
        sortable: false,
        value: "quantityYTD_Formatted",
        align: this.showSalesColumns ? 'start' :' d-none'
      },    
      {
        text: "m3 LYTD",
        sortable: false,
        value: "quantityLYTD_Formatted",
        align: this.showSalesColumns ? 'start' :' d-none'
      },    
      {
        text: "m3 LY",
        sortable: false,
        value: "quantityLY_Formatted",
        align: this.showSalesColumns ? 'start' :' d-none'
      },                        
      {
        text: "m3 Potential",
        sortable: false,
        value: "estimatedConsumption_Formatted",
        align: 'start'
      }   
      ]    
    }
  },
  methods: {
    showEngagementHistory(item){
      this.selected = item;
      this.dialog = true
    },
    async toggleEngagement(item) {
      let result;
      switch (item.isEngaged) {
        case false:
          result = await salesrepService.DisableEngagement(this.lifecycleType, item.organizationId, "Activate");
          break;
        case true:
          result = await salesrepService.CreateEngagement(this.lifecycleType, item.organizationId, "Disable");
          break;
        default:
          break;
      }

      if (!result.succeeded) {
        const snackbar = { show: true, message: "", errors: result.errors };
        this.$store.dispatch("util/setSnackbar", snackbar);
        return;
      }

      
    },
    setType(val){          
      this.$store.dispatch("filter/setLifecycleType", val)
      this.refresh(1, 10, "")
    } , 
    async GetSalesrepDetails(){
      this.salesrepDetails = await salesrepService.GetSalesrepById(this.salesrep)
    },
    async exportdata(){
      this.loadingDownload = true
      this.$store.dispatch("util/setLoading", {visible: true, text: "Generating Excel file"});
      let report = await salesrepService.GetExcel(this.salesrep, this.lifecycleType );

      this.$store.dispatch("util/resetLoading");

      const url_1 = window.URL.createObjectURL(new Blob([report]));
      const link = document.createElement("a");
      link.href = url_1;

      var reportName = `${this.salesrepDetails.name}_${this.lifecycleTypeSelected.name}`
      var filedate = new Date()
        .toISOString()
        .slice(-24)
        .replace(/\D/g, "")
        .slice(0, 14);
      link.setAttribute("download", `${reportName}_${filedate}` + ".xlsx");
      document.body.appendChild(link);
      link.click();
      

      this.loadingDownload = false
    },
  async refresh(pageNumber, pageSize, search) {
      this.items = []
      if(!this.salesrep)
        return

      this.loading = true;
      let result = null;
      
      if(this.lifecycleType === 1){
        result = await salesrepService.GetCustomersPaginated(
          this.salesrep,
          this.showOnlyEngaged,
          pageNumber,
          pageSize,
          search
        );
      }
      else {
          result = await salesrepService.GetProspectsPaginated(
          this.salesrep,
          this.showOnlyEngaged,
          pageNumber,
          pageSize,
          search
        );
      }


      this.items = result.items;
      this.itemsCount = result.totalCount;
      this.hasPreviousPage = result.hasPreviousPage;
      this.hasNextPage = result.hasNextPage;

      this.loading = false;
    },
    async paginate(val) {
      if(this.loading) return
      await this.refresh(val.page, val.itemsPerPage, this.search);
    },
    async performSearch(val) {
      if(this.loading) return
      await this.refresh(1, 10, val);
    }    
  }
};



</script>