<template>
    <v-card>
        <v-card-subtitle>
            {{this.engagements}}
        </v-card-subtitle>
        <v-card-text>
        </v-card-text>
    </v-card>
</template>

<script>
import salesrepService from "@/services/SalesrepService";

export default {
    props:{
        lifecycleType: {type: Number, default: 1},
        organization: {type: Object, default: null}
    },
  data: () => ({
    engagements: []
  }),    
    async mounted(){
        await this.refresh()
    },
    methods:{
       async refresh(){
            this.engagements = await salesrepService.GetOrganizationEngagementHistory(this.lifecycleType, this.organization.organizationId)
        }
    }

}
</script>